import React, { useState } from "react";
import { Box, Button, Typography, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  addcartBox: {
    position: "relative",
    // padding: "20px 0",
    "& .addCardinnerBox": {
      position: "relative",
      backgroundSize: "cover",
      alignItems: "flexStart",
      backgroundImage: "url(images/redBG.svg)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center bottom",
      width: "83.692px",
      height: "91.375px",
      "& .centerImage": {
        width: "auto",
        maxWidth: "20px",
      },
    },
    "& .centerContentBox": {
      marginRight: "70px",
      [theme.breakpoints.down("xs")]:{
        marginRight: "10px",
      },
    },
    "& .borderTop": {
      borderTop: "1px solid rgba(255, 255, 255, 0.60)",
    },
  },
}));

const AddcartCard = (wineData) => {
  const classes = useStyles();
  const [isFavorite, setIsFavorite] = useState(false);

  const handleFavoriteClick = () => {
    setIsFavorite(!isFavorite);
  };

  return (
    <Box className={classes.addcartBox}>
        <Box className="displaySpacebetween"style={{ alignItems: "flex-start" }} >
        <Typography variant="body1" style={{ color: "#fff" }}>
        1 item
        </Typography>
  
        <Typography variant="body1" style={{ color: "#fff" }}>
        Clear all
        </Typography>
  
      </Box>
      <Box className="displaySpacebetween" mt={2}>
        <Box className="displayStart" style={{ alignItems: "flex-start" }}>
          <Box className="addCardBox">
            <Box className="addCardinnerBox displayCenter" mr={2}>
              <img
                src="images/wine1.svg"
                alt="Product"
                className="centerImage"
              />
            </Box>
          </Box>
          <Box className="centerContentBox">
            <Typography variant="body2" style={{ color: "#fff" }}>
              Weingut Egon Müller
            </Typography>
            <Box>
              <Typography variant="body1" style={{ color: "#fff" }}>
                Expires Today At 05 july,2023
              </Typography>
            </Box>
          </Box>
        </Box>

        <Typography variant="body1" style={{ color: "#fff" }}>
          0.024 USD
        </Typography>
      </Box>
      <Box mt={6} mb={2}>
        <Box className="borderTop"></Box>
      </Box>
      <Box className="displaySpacebetween"style={{ alignItems: "flex-start" }} >
        <Typography variant="body2" style={{ color: "#fff" }}>
        Total price 
        </Typography>
        <Box align="right">
        <Typography variant="body2" style={{ color: "#fff" }}>
        0.024 USD
        </Typography>
        <Typography variant="body1" style={{ color: "#fff" }}>
          0.024 USD
        </Typography>
        </Box>
  
      </Box>
    </Box>
  );
};

export default AddcartCard;
