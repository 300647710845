import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  MenuItem,
  Box,
  Container,
  Typography,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Avatar,
  Slide,
  TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "./../../component/Logo";
import { NavLink } from "react-router-dom";
import { UserContext } from "src/context/User";
import { useWeb3React } from "@web3-react/core";
import { IoIosNotifications } from "react-icons/io";
import { AiFillCaretDown } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import AddcartCard from "src/component/AddcartCard";
const headersData = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Marketplace",
    href: "/marketplace-view",
  },
  {
    label: "Brands",
    href: "/brands",
  },
  {
    label: "Wineries",
    href: "/product",
  },
];
const announcement = [{}];

const useStyles = makeStyles((theme) => ({
  menubox: {
    "& .Component-paper": {
      marginTop: "20px",
    },
    "& .MuiPopover-paper": {
      outline: "0",
      position: "absolute",
      maxHeight: "calc(100% - 32px)",
      minHeight: "16px",
      overflowX: "hidden",
      overflowY: "auto",
      border: "1px solid #2C235A !important",
      borderRadius: "10px",
      backdropFilter: "blur(100px)",
      background: "rgba(137, 113, 253, 0.10) !important",
    },
    "& ul": {
      "& .MuiMenuItem-root": {
        color: "rgba(255, 255, 255, 0.87)",
        fontSize: "13px",
        borderBottom: "1px solid transparent",
        padding: "10px",
        "&:not(:last-child)": {
          borderBottom: "1px solid rgb(255 255 255 / 17%)",
          padding: "10px",
        },
      },
      "& a": {
        fontSize: "15px",
        fontWeight: "500",
        color: "#B7B7B7",
        "&.active": {
          color: "#fff",
        },
        "&:hover": {
          color: "#fff",
        },
      },
    },
    "& .iconBox": {
      marginRight: "10px",
      width: "18px",
    },
  },
  menuButton: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "400",
    borderRadius: 0,
    minWidth: "auto",
    color: "#fff",
    padding: "5px 7px",
    textDecoration: " none",
    margin: "0 9px",
    borderBottom: "2px solid transparent",
    position: "relative",

    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&.active": {
      color: "#F43755",
      borderBottom: "2px solid #F43755",
    },
    "&:hover": {
      color: "#F43755",
      borderBottom: "2px solid #F43755",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
    padding: " 10px 0px",
    // marginTop: 20,
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    // paddingLeft: "10px",
    width: "140px",
    // marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px 20px 20px",
    height: "100%",
    background: "#161032",
    background: "#161032",
    width: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    fontSize: "25px",
    paddingRight: "0px",
    marginLeft: "0px !important",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
      width: "75px",
    },
  },
  menuMobile: {
    padding: "16px",
    "@media (max-width: 500px)": {
      padding: "7px 0",
      width: "100%",
    },
  },

  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },

  menuButton1: {
    paddingLeft: "0",
  },
  menuMobile1: {
    padding: "15px 0",
    "& h4": {
      fontSize: "14px !important",
      lineHeight: " 17px",
      color: theme.palette.text.main,
      margin: "0 8px",
      fontWeight: "400",
      [theme.breakpoints.only("xs")]: {
        fontSize: "12px !important",
      },
    },
    "& svg": {
      color: theme.palette.text.main,
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& figure": {
      margin: 0,
      width: 40,
      height: 40,
      borderRadius: "50px",
      overflow: "hidden",
      display: "flex",
      justifyContent: " center",
      alignItems: "center",
      "& img": {
        width: "auto",
        height: "auto",
        maxWidth: "100%",
        // maxHeight: "100%",
      },
    },
  },

  sidebarBox: {
    // maxWidth: "400px",
    // width: "100%",
    padding: "20px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "355px",
    },
  },
}));

export default function Header() {
  const { account, chainId } = useWeb3React();
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const [open11, setOpen11] = React.useState(false);

  const handleDrawerOpen11 = () => {
    setOpen11(true);
  };
  const handleDrawerClose11 = () => {
    setOpen11(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const user = useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [updateName, setUpdateName] = useState(false);
  // const searchTextRef = React.useRef(null);
  const location = useLocation();
  const [networkDetails, setNetworkDetails] = React.useState([]);

  const handleClose4 = () => {
    setAnchorEl1(null);
  };

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose5 = () => {
    setAnchorEl2(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const {
    menuMobile,
    menuButton,
    menuButton1,
    toolbar,
    menubox,
    sidebarBox,
    drawerContainer,
    menuMobile1,
    drawericon,
    logoDrawer,
    mainHeader,
  } = useStyles();
  const history = useHistory();

  const [updateMinSatkeOpen, setUpdateMinSatkeOpen] = useState(false);
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const [open1, setOpen1] = useState({ community: false, user: false });
  const anchorRef = { community: useRef(null), user: useRef(null) };
  const StyledMenu = withStyles({
    paper: {
      marginTop: "2px",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  function handleListKeyDown(event, name) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen1({ ...open1, [name]: false });
    }
  }
  const [disconnect, setDisconnect] = useState(false);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const handleOpendisconnect = () => {
    setDisconnect(true);
    setAnchorEl(null);
  };
  const displayDesktop = () => {
    return (
      // <Container maxWidth="lg" className="p-0">
      <Toolbar className={toolbar}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          {femmecubatorLogo}

          {/* {SearchBoxx} */}
        </Box>
        <Box className="displayStart serchBox">
          <TextField
            style={{ width: "100%", maxWidth: "225px" }}
            variant="outlined"
            placeholder="Search by brands name or category...."
            fullWidth
            type="search"
          />
          &nbsp;&nbsp;
          <IconButton style={{ padding: "0px", background: "transparent" }}>
            <img src="images/searchbutton.png" alt="search" width="40px" />
          </IconButton>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          {getMenuButtons()}
          <IconButton>
            <IoIosNotifications style={{ color: "#fff", fontSize: "30px" }} />
          </IconButton>
          <IconButton onClick={handleDrawerOpen11}>
            <img src="/images/cart.svg" width="25px" />
          </IconButton>

          <Box
            ml={1}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={handleClick}
          >
            <IconButton onClick={handleClick} className="accountMenuBox">
              A
            </IconButton>

            <AiFillCaretDown style={{ color: "#fff", marginLeft: "5px" }} />
          </Box>
          <StyledMenu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            className={menubox}
            onClose={handleClose1}
          >
            <MenuItem onClick={() => history.push("/my-profile")}>
              <Box className="displayStart">
                <img
                  src="/images/Account/account.svg"
                  alt="Account"
                  className="iconBox"
                />
                Profile
              </Box>
            </MenuItem>
            <MenuItem onClick={() => history.push("/favourite")}>
              <Box className="displayStart">
                <img
                  src="/images/Account/wislist.svg"
                  alt="Account"
                  className="iconBox"
                />
                My Wishlist
              </Box>
            </MenuItem>
            <MenuItem onClick={() => history.push("/wallet")}>
              <Box className="displayStart">
                <img
                  src="/images/Account/wallet.svg"
                  alt="Account"
                  className="iconBox"
                />
                My Wallet
              </Box>
            </MenuItem>
            <MenuItem onClick={handleOpendisconnect}>
              <Box className="displayStart">
                <img
                  src="/images/Account/logout.svg"
                  alt="Account"
                  className="iconBox"
                />
                Logout
              </Box>
            </MenuItem>
          </StyledMenu>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            startIcon={<img src="/images/connect.svg" />}
            style={{ marginLeft: "15px" }}
          >
            Connect Wallet
          </Button>
        </Box>
      </Toolbar>
      // {/* </Container> */}
    );
  };

  const [searchInput, setSearchInput] = useState("");
  const searchTextRef = React.useRef(null);

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    //mobile
    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/logo.svg" alt="" />
            {getDrawerChoices()}
            <div>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickOpen}
                  startIcon={<img src="/images/connect.svg" />}
                  style={{ whiteSpace: "pre", width: "94%" }}
                >
                  Connect Wallet
                </Button>
              </Box>
            </div>

            <Box className="displayStart serchBox" mt={2}>
              <TextField
                style={{ width: "100%", maxWidth: "225px" }}
                variant="outlined"
                placeholder="Search by brands name or category...."
                fullWidth
                type="search"
              />
              &nbsp;&nbsp;
              <IconButton style={{ padding: "0px", background: "transparent" }}>
                <img src="images/searchbutton.png" alt="search" width="40px" />
              </IconButton>
            </Box>
          </div>
        </Drawer>

        <Box display="flex" justifyContent="space-between">
          {femmecubatorLogo}
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton style={{ padding: "4px" }}>
            <IoIosNotifications style={{ color: "#fff", fontSize: "20px" }} />
          </IconButton>
          <IconButton onClick={handleDrawerOpen11} style={{ padding: "4px" }}>
            <img src="/images/cart.svg" width="20px" />
          </IconButton>
          <Box
            ml={1}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={handleClick}
          >
            <IconButton onClick={handleClick} className="accountMenuBox">
              A
            </IconButton>

            <AiFillCaretDown style={{ color: "#fff", marginLeft: "5px" }} />
          </Box>
          <StyledMenu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            className={menubox}
            onClose={handleClose1}
          >
            <MenuItem onClick={() => history.push("/my-profile")}>
              <Box className="displayStart">
                <img
                  src="/images/Account/account.svg"
                  alt="Account"
                  className="iconBox"
                />
                Profile
              </Box>
            </MenuItem>
            <MenuItem onClick={() => history.push("/favourite")}>
              <Box className="displayStart">
                <img
                  src="/images/Account/wislist.svg"
                  alt="Account"
                  className="iconBox"
                />
                My Wishlist
              </Box>
            </MenuItem>
            <MenuItem onClick={() => history.push("/wallet")}>
              <Box className="displayStart">
                <img
                  src="/images/Account/wallet.svg"
                  alt="Account"
                  className="iconBox"
                />
                My Wallet
              </Box>
            </MenuItem>
            <MenuItem onClick={handleOpendisconnect}>
              <Box className="displayStart">
                <img
                  src="/images/Account/logout.svg"
                  alt="Account"
                  className="iconBox"
                />
                Logout
              </Box>
            </MenuItem>
          </StyledMenu>
          <IconButton
            className={drawericon}
            {...{
              edge: "start",
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon
              width="60px"
              height="60px"
              style={{ color: "#F43755", fontSize: "26px" }}
            />
          </IconButton>
        </Box>
      </Toolbar>
    );
  };
  //mobile end
  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton1,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Button>
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );
  const getMenuButtons = (activeClassName) => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <NavLink
            exact
            // to={`${href}`}
            {...{
              key: label,
              color: "inherit",
              to: href,
              // component: Link,
              className: menuButton,
              activeClassName: "active",
            }}
          >
            {" "}
            {label}
          </NavLink>
        </>
      );
    });
  };

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{ backgroundColor: "#FFFFFF0D", border: "none" }}
      >
        <Container maxWidth="lg">
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>

        <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"xs"}>
          <IconButton
            onClick={handleClose}
            style={{ position: "absolute", right: "0" }}
          >
            <CloseIcon style={{ color: "rgba(255, 255, 255, 0.60)" }} />
          </IconButton>
          <DialogTitle
            id="alert-dialog-title"
            style={{ padding: "16px 24px 0" }}
          >
            <Typography
              variant="h5"
              style={{ color: "#FFF", marginTop: "20px" }}
            >
              Connect your Wallet
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography
                variant="body2"
                style={{
                  marginBottom: "25px",
                  color: "rgba(255, 255, 255, 0.60)",
                  fontSize: "12px",
                }}
              >
                Please connect a wallet to continue your purchase
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box className="connectwalletBox displayCenter">
                    <img src="images/mask_1.png" alt="Metamask" />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="connectwalletBox displayCenter">
                    <img
                      src="images/banner/trustwaller.png"
                      alt="Trust Wallet"
                    />
                  </Box>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Box mb={4} mt={1} style={{ width: "100%" }}>
              <Button
                onClick={handleClose}
                color="primary"
                variant="contained"
                fullWidth
              >
                Connect
              </Button>
            </Box>
          </DialogActions>
        </Dialog>

        <Drawer
          variant="temporary"
          anchor="right"
          open={open11}
          className="carddrawerBox1"
          onClose={handleDrawerClose11}
          style={{ overflowY: "scroll" }}
        >
          <Box className={sidebarBox} style={{}}>
            <Box>
              <IconButton
                style={{ position: "absolute", top: "0px", right: "5px" }}
                onClick={handleDrawerClose11}
              >
                <IoClose style={{ color: "#fff" }} />
              </IconButton>
            </Box>
            <Box
              align="left"
              mb={2}
              mt={1}
              pb={2}
              style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.60)" }}
            >
              <Typography variant="h4" style={{ color: "#fff" }}>
                Your cart
              </Typography>
            </Box>
            <Grid container>
              <Grid item xs={12}>
                {announcement.map(() => (
                  <AddcartCard />
                ))}
              </Grid>
            </Grid>
            <Box align="right" mt={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={()=>history.push("/payment-method")}
              >
                Complete purchase
              </Button>
            </Box>
          </Box>
        </Drawer>

        <Dialog
          maxWidth="xs"
          fullWidth
          className={classes.dailogOpen}
          open={disconnect}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setDisconnect(false)}
        >
          <Box className="mainbox1">
            <DialogContent>
              <Box className={classes.dialougTitle} align="center">
                <img src="images/Account/logoutmodal.svg" alt="Logout" />
                <Box mt={2}>
                  <Typography
                    variant="body2"
                    style={{
                      color: "rgb(255, 255, 255)",
                      width: "auto",
                      maxWidth: "200px",
                      lineHeight: "20px",
                    }}
                  >
                    Are you sure, you want to logout?
                  </Typography>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions
              style={{
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Box mt={2} mb={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setDisconnect(false)}
                >
                  No
                </Button>
                &nbsp; &nbsp;
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setDisconnect(false)}
                >
                  Yes
                </Button>
              </Box>
            </DialogActions>
          </Box>
        </Dialog>
      </AppBar>
    </>
  );
}
