import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/payment-method",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Payment/Payment")),
  },
  {
    exact: true,
    path: "/wallet",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Saled/Saled")),
  },
  {
    exact: true,
    path: "/my-profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Profile/index")),
  },
  {
    exact: true,
    path: "/redeem",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Saled/Redeem")),
  },
  {
    exact: true,
    path: "/add-addrees",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Saled/NewCheckout.js")),
  },
  {
    exact: true,
    path: "/order-status",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Saled/OrderDetails")),
  },
  {
    exact: true,
    path: "/collections",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Saled/ViewCollection")),
  },
  {
    exact: true,
    path: "/view-wine",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Viewwnie/Viewwnie")),
  },
  {
    exact: true,
    path: "/wine-market",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Viewwnie/VineMarket")),
  },
  {
    exact: true,
    path: "/favourite",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/FavouriteWine")),
  },
  {
    exact: true,
    path: "/brand",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Brand")),
  },
  {
    exact: true,
    path: "/brands",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Brands")),
  },
  {
    exact: true,
    path: "/product",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Product")),
  },
  {
    exact: true,
    path: "/marketplace-view",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Marketplaceview")),
  },
  // {
  //   exact: true,
  //   path: "/set-password",
  //   layout: LoginLayout,
  //   component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
  // },
  // {
  //   exact: true,
  //   path: "/forgot",
  //   layout: LoginLayout,
  //   component: lazy(() => import("src/views/pages/Auth/Forgot")),
  // },
  // {
  //   exact: true,
  //   path: "/signup",
  //   layout: LoginLayout,
  //   component: lazy(() => import("src/views/pages/Auth/Signup")),
  // },
  // {
  //   exact: true,
  //   path: "/login",
  //   layout: LoginLayout,
  //   component: lazy(() => import("src/views/pages/Auth/Login")),
  // },
  {
    exact: true,
    path: "/faqs",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/FAQs/Index")),
  },
  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/About/About")),
  },
  {
    exact: true,
    path: "/terms-conditions",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/T&C/Term")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Privacy/Privacy")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
