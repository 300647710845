import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";

import typography from "./typography";

const baseOptions = {
  direction: "ltr",
  typography,
  overrides: {
    MuiDrawer: {
      paperAnchorRight: {
        // border: "1px solid #2C235A",
        background: "#1A142E",
        borderRadius: "0px",
      },
    },
    MuiInputBase: {
      input: {
        color: "#fff",
      },
    },
    MuiSelect: {
      selectMenu: {
        color: "#fff",
        textTransform: "uppercase",
        whiteSpace: "nowrap",
        fontFamily: "Vidaloka",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "14px",
        color: "#fff",
      },
    },
    MuiDialogContentText: {
      root: {
        marginBottom: "0px",
      },
    },
    MuiDialogActions: {
      root: {
        padding: "8px 24px",
      },
    },
    MuiDialog: {
      paper: {
        border: "1px solid #2C235A",
        margin: "32px",
        position: "relative",
        background: "rgba(137, 113, 253, 0.10)",
        overflowY: "auto",
        backdropFilter: "blur(100px)",
        borderRadius: "10px",
      },
      paperWidthXs: {
        maxWidth: "500px",
      },
    },
    MuiTableCell: {
      root: {
        padding: "15px",
      },
    },
    MuiTableHead: {
      root: {
        background: "rgba(217, 217, 217, 0.10)",
      },
    },
    MuiOutlinedInput: {
      root: {
        position: "relative",
        borderRadius: "10px",
        border: "1px solid rgba(255, 255, 255, 0.40)",
      },
      input: {
        padding: "14px 14px",
      },
    },
    MuiTableCell: {
      root: {
        padding: "7px",
        fontSize: "12px",
        borderBottom: "none",
      },
      head: {
        fontWeight: "200",
        fontSize: "14px",
      },
    },
    MuiInputAdornment: {
      positionStart: {
        paddingLeft: "14px",
      },
    },

    MuiButton: {
      outlinedSizeSmall: {
        padding: "7px 18px",
        fontSize: "14px",
        lineHeight: "21px",
        fontWeight: "500",
      },
      containedPrimary: {
        color: "#fff",
        filter: "drop-shadow(0px 13px 27px rgba(0, 0, 0, 0.25))",
        padding: "10px 30px",
        fontSize: "14px",
        borderRadius: "10px",
        background: "linear-gradient(165deg, #FF9AAB -64.52%, #F53756 61.26%)",
        fontWeight: "500",
        lineHeight: "21px",
        backgroundColor: "#898989",
        textTransform: "capitalize",
        whiteSpace: "pre",
        "&:hover": {
          color: "#ffffff",
          background: "#323232",
          boxShadow: "none",
          backgroundColor: "#323232",
        },
      },
      containedSecondary: {
        backgroundColor: "rgba(255, 255, 255, 0.04)",
        padding: "8px 27px",
        filter: "drop-shadow(0px 13px 27px rgba(0, 0, 0, 0.25))",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "21px",
        color: "#ffffff",
        borderRadius: "50px",
        border: "2px solid ",
        borderColor: "rgba(255, 255, 255, 0.04)",
        "&:hover": {
          color: "#ffffff",
          background: "transparent",
          boxShadow:
            "0 1px 0 0 #ff00cd, 0 -1px 0 0 #7d00b9, 1px 0 0 0 #f5673f, -1px 0 0 0 #f5673f, 1px -1px 0 0 #f5673f, -1px 1px 0 0 #f5673f, 1px 1px 0 0 #f5673f, -1px -1px 0 0 #f5673f",
          backgroundColor: "transparent",
        },
      },
      // containedSizeMedium: {
      //   backgroundColor: "green",
      //   padding: "8px 60px",
      //   filter: "drop-shadow(0px 13px 27px rgba(0, 0, 0, 0.25))",
      //   fontSize: "14px",
      //   fontWeight: "500",
      //   lineHeight: "21px",
      //   color: "#ffffff",
      //   borderRadius: "50px",
      //   border: "2px solid ",
      //   borderColor: "rgba(255, 255, 255, 0.04)",
      //   "&:hover": {
      //     color: "#ffffff",
      //     background: "red",
      //     boxShadow:
      //       "0 1px 0 0 #ff00cd, 0 -1px 0 0 #7d00b9, 1px 0 0 0 #f5673f, -1px 0 0 0 #f5673f, 1px -1px 0 0 #f5673f, -1px 1px 0 0 #f5673f, 1px 1px 0 0 #f5673f, -1px -1px 0 0 #f5673f",
      //     backgroundColor: "transparent",
      //   },
      // },
      contained: {
        "&.Mui-disabled": {
          backgroundColor: "rgba(255, 255, 255, 0.025) ",
          color: "#ffffff45",
        },
      },
      outlinedPrimary: {
        color: "#F43755",
        border: "0.5px solid #F43755 !important",
        padding: "7px 28px",
        fontSize: "14px",
        background: "transparent",
        borderRadius: "10px",
        fontWeight: "500",
        textTransform: "capitalize",
        whiteSpace: "pre",
        "&:hover": {
          background:
            "linear-gradient(165deg, #FF9AAB -64.52%, #F53756 61.26%)",
          color: "#fff",
        },
      },
    },
  },
};

const themesOptions = [
  {
    name: "LIGHT",
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: "#050404",
        },
        elevation1: {
          border: "1px solid rgba(255, 255, 255, 0.04)",
          background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04)), linear-gradient(0deg, rgba(255, 255, 255, 0.025), rgba(255, 255, 255, 0.025))",
          borderRadius: "10px",
          padding: "10px",
          "@media(max-width:767px)": {
            padding: "10px !important",
          },
        },
      },
      MuiTableCell: {
        body: {
          color: "#000",
        },
      },
      MuiMenu: {
        list: {
          outline: "0",
          background: "#140e2e",
        },
      },

      MuiIconButton: {
        root: {
          color: "#000000",
        },
      },
    },
    typography: {
      // fontFamily: "'K2D', sans-serif"
    },
    palette: {
      background: {
        sellercard: "#fff",
        cardinner: "#fff",
        card: "#F7F7F7",
        tabButton: "#fff",
        default: "#cccccc",
        gradientbox: "#cccccc",
      },
      primary: {
        main: "#000000", //black
      },
      secondary: {
        main: "#000000", //black
        icons: "#009900", //white
      },
      text: {
        primary: "#000", //black
        secondary: "#000", //white
        gray: "rgba(0, 0, 0, 0.6)",
        graydark: "rgba(13, 13, 13, 0.3)",
      },
    },
  },
  {
    name: "DARK",
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: "#050404",
        },
        elevation1: {
          border: "1px solid rgba(255, 255, 255, 0.04)",
          background: "#161032",
          borderRadius: "10px",
          padding: "20px",
          // "@media(max-width:767px)": {
          //   padding: "10px !important",
          // },
        },
        elevation2: {
          border: "1px solid rgba(255, 255, 255, 0.04)",
          background: "rgba(69, 46, 84, 0.25)",
          borderRadius: "10px",
          padding: "20px",
          // "@media(max-width:767px)": {
          //   padding: "10px !important",
          // },
        },
      },
      MuiTableCell: {
        body: {
          color: "rgba(255, 255, 255, 0.87)",
        },
      },
      MuiMenu: {
        list: {
          outline: "0",
          background: "#140e2e",
        },
      },

      MuiIconButton: {
        root: {
          color: "#fff",
        },
      },
    },
    typography: {
      fontFamily: "'K2D', sans-serif",
    },
    palette: {
      background: {
        sellercard: "#000000",
        card: "rgba(255, 255, 255, 0.02)",
        cardinner: "rgba(255, 255, 255, 0.015)",
        tabButton: "rgb(21 18 20)",
        default: "#000000",
        gradientbox: "rgb(15 11 11 / 92%)",
      },
      primary: {
        main: "#ffffff", //black
      },
      secondary: {
        main: "#FFFFFF", //white
        icons: "#FFFFFF", //white
      },
      text: {
        primary: "#FFFFFF", //white
        secondary: "rgba(255, 255, 255, 0.6)", //white
        gray: "rgba(255, 255, 255, 0.6)",
        graydark: "rgba(255, 255, 255, 0.3)",
      },
    },
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
